import React from 'react';

import Welcome from './Welcome/Welcome';
import About from './About/About';
import Plans from './Plans/Plans';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Navigation from './Navigation/Navigation';
// import { StickyBanner } from './StickyBanner/StickyBanner';

const App = ({ location }) => {
  return (
    <>
      <Navigation location={location} />
      {/* <StickyBanner /> */}
      <Welcome />
      <About />
      <Plans />
      <Contact />
      <Footer backToTop />
    </>
  );
};

export default App;
